$pricing-table-spacing: $gap/2;

.pricing-table {
	text-align: center;
	padding: $gap $gap;
	border-radius: 10px;

	background-color: lighten($dark-blue, 2.5%);
	box-shadow: $shadow-default;
	transition: all 300ms ease;
	margin-bottom: $gap;


	@include hov-foc {
		background-color: darken($dark-blue, 2.5%);
	}

	.button {
		margin-bottom: 0px;
	}
}

.pricing-table__content {
	text-align: center;
	.headline {
		font-size: 1.15rem;
		line-height: 1.2;
		margin-bottom: $pricing-table-spacing*2;
	}

	ul:not(.checklist--height) {
		margin-bottom: $pricing-table-spacing*2;

	}
}

.pricing-table__logo {
	img {
		width: 50%;
		min-width: 250px;
	}

	border-bottom: 5px solid $dark-blue;
	padding-bottom: $pricing-table-spacing*2;
	margin-bottom: $pricing-table-spacing*2;
}
