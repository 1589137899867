.nav {
	@include gradient(to right, $dark-purple, $light-purple);
}

.nav__main {
	padding: $gap/1.5 0;

	a {
		color: $white;
		font-size: 1.25rem;
		transition: all 0.3s ease;
		font-weight: 600;
		text-align: center;

		@include hov-foc {
			background: darken($light-blue, 5%);
		}

		&.active {
			background: $light-blue;
		}
	}

	.menu {
		margin: 0 auto;
		display: table;

		li {
			border-right: 1px solid $white;

		}

		li {
			&:last-child {
				border-right: none;
			}
		}
	}
}

@include breakpoint(large) {
	.nav__main {
		a {
			font-size: 1.5rem;
		}
	}
}

@include breakpoint(xlarge) {
	.nav__main {
		a {
			font-size: 1.65rem;
		}
	}
}
