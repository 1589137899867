@mixin green-sections($br, $bulb, $content) {
	@media screen and (min-width: #{$br}px) {
		.bulb__img {
			top: #{$bulb}px;
		}

		.green-content {
			margin-top: -#{$content}px;
		}
	}
}

.bulb__img {
	position: relative;
	top: 145px;
	left: 50%;
	transform: translateX(-50%);
	width: 130%;
}

.green-content {
	margin-top: -200px;

	.column {
		position: relative;
		z-index: 99;
	}

	.bulb {
		z-index: 50;
	}
}

.green-content__region {
	margin-top: $paragraph-margin-bottom*2;
}

@include green-sections('400', '175', '230');
@include green-sections('450', '205', '260');
@include green-sections('500', '235', '290');
@include green-sections('550', '265', '320');
@include green-sections('600', '295', '350');

@include breakpoint(medium) {
	.bulb__img {
		top: -80px;
		width: 150%;
		position: absolute;
	}

	.green-content {
		margin-top: 15px;
	}

	.green-content__row2 {
		margin-top: 50px;
	}
}

@include breakpoint(large) {
	.bulb__img {
		top: -120px;
		width: 150%;
	}

	.green-content {
		margin-top: 30px;
	}

	.green-content__row2 {
		margin-top: 100px;
	}

}

@include breakpoint(xlarge) {
	.bulb__img {
		top: -140px;
		width: 150%;
	}

	.green-content {
		margin-top: 30px;
	}

	.green-content__row2 {
		margin-top: 100px;
	}

}
