@mixin text-highlighting($background, $color) {
	::selection {
		background: $background;
		color: $color;
	}
	::-moz-selection {
		background: $background;
		color: $color;
	}
}

@mixin x-center() {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

@mixin y-center() {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

@mixin xy-center() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// Render necessary classes for mixins

.xy-center {
	@include xy-center();
}

.x-center {
	@include x-center();
}

.y-center {
	@include y-center();
}
