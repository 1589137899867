$gap: map-get($grid-column-gutter, medium);
$gap-small: map-get($grid-column-gutter, small);

// Colors

$dark-blue: #2f56a8;
$light-blue: #2183ca;

$dark-purple: #672370;
$light-purple: #a2156f;

$shadow-default:  0px 0px 20px 2px transparentize($black, 0.8);

$blue-01: #91B9DF;
$blue-02: #1e376c;
$blue-03: #2183CA;
$blue-05: #91B9DF;

$xsmall: 420;

$border-radius: 5px;

