section {
	h1 {
		//text-transform: uppercase;
		margin-bottom: $gap;

		span {
			text-transform: none;
		}
	}
}

@include breakpoint(large) {
	section {
		h1 {
			margin-bottom: $gap*1.5;
		}
	}
}

header {
	h1 {
		font-size: rem-calc(34);
	}
}


h4, h5 {
	font-weight: 600;
}


.header-desc {
	display: block;
	font-weight: normal;
	font-size: 1rem;
	margin-top: -4px;
	font-style: italic;
}

.headline--special {
	//@include gradient(to right, $dark-purple, $light-purple);
	color: $white;
	text-align: center;
	padding: $global-padding;
	background-color: $secondary-color;

}
