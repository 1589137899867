.section {
	position: relative;

	> div {
		&:nth-child(2) {
			padding-top: $gap;
		}
	}
}

section, footer {
	padding: $gap*2 0;
}

//footer {
//	padding: $gap 0;
//
//	position: relative;
//
//	> div {
//		&:nth-child(2) {
//			padding-top: $gap*2;
//		}
//	}
//}



@include breakpoint(large) {
	.section {
		> div {
			&:nth-child(2) {
				padding-top: $gap*2;
			}
		}
	}

	section, footer {
		padding: $gap*3 0;

		&.section--bg {
			padding: $gap*4 0;
		}
	}

	footer {
		padding-bottom: $gap;
	}
}
