@mixin testimonial-content($color) {
	.testimonial__content {
		background-color: $color;

		&::after {
			border-color: transparent transparent $color transparent;
		}

	}

	@include breakpoint(medium) {
		.testimonial__content {
			&::after {
				border-color: transparent $color transparent transparent;
			}
		}
	}
}

.content__slider__item {
	padding: 0 $gap;
}

.testimonial__image {
	margin-bottom: $gap/2 !important;

	img {
		margin: 0 auto;
		border-radius: 50%;
		max-width: 150px;
		box-shadow: $shadow-default;
		border: 5px solid $white;
	}
}

.content__slider {
	margin: 0 $gap*1.5;
	position: relative;

	.content__slider__item {
		&:nth-child(odd) {
				@include testimonial-content($blue-03)
		}

		&:nth-child(even) {
				@include testimonial-content($blue-02)
		}
	}

	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		outline: 0;

		span {
			font-size: 3rem;
		}
	}

	.slick-prev {
		left: -40px;
	}

	.slick-next {
		right: -40px;
	}
}

.testimonial__content {
	padding: $gap/2;
	border-radius: 10px;

	blockquote {
		color: $primary-color;
		margin-bottom: 0;
	}

	position: relative;

	&::after {
		content: '';
		display: block;
		width: 0px;
		height: 0px;
		border-style: solid;
		position: absolute;
		border-width: 0 20px 15px 20px;
		transform: translateX(-50%) rotate(360deg);
		top: -15px;
		left: 50%;
	}
}

@include breakpoint(medium) {
	.content__slider__item {
		.column {
			width: initial;
		}
	}

	.testimonial__content {
		margin-left: $gap/2;
		&::after {
			top: 50%;
			left: -13px;
			transform: translateY(-50%) rotate(360deg);
			border-width: 20px 15px 20px 0;
		}
	}

	.testimonial__image {
		margin-bottom: 0px !important;
	}
}

.content__slider--partner {
	.content__slider__item {
		img {
			display: block;
			//max-width: 250px;
			//@include margin-auto;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}


