.mobile-overlay {
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 200;
	@include gradient(to right, $dark-purple, $light-purple);

	visibility: hidden;
	overflow-x: auto;

	transform:translateY(-100%);
	transition:transform 0.4s ease-in-out, visibility 0s 0.4s;

	&.is-active {
		visibility: visible;
		transform: translateY(0%);
		transition: transform 0.4s ease-in-out;
	}
}

.mobile-overlay__nav {
	text-align: center;
	display: table;
	width: 100%;
	height: 100%;
	padding-top: $gap*2;
	padding-bottom: $gap*3;

	> .menu {
		display: table-cell;
		vertical-align: middle;
	}

	a {
		color: $white;
		font-size: 28px;

		@include hov-foc {
			background-color: $light-blue;
		}

		&.active {
			background-color: $light-blue;
		}
	}

	.nested {
		a {
			font-size: 21px;
		}
	}
}
