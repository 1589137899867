.blue {
	background-color: $dark-blue;
}


.section--bg {
	background-repeat: no-repeat;
	background-position: center;
	overflow: hidden;
	background-size: cover;
}

.section--gray {
	background-image: url('../img/sections/spende-background.png');
}

.section--green {
	background-image: url('../img/sections/green-background.png');
}

.seperator {
	position: absolute;
	left: 0;
	z-index: 99;
	width: 100%;
	overflow: hidden;
	svg {
		width: 110%;
		margin-left: -5px;
	}
}

html.ie {
	.seperator--top {
		top: -26px;
	}

	.seperator--bottom {
		bottom: -33px;
	}
}

.seperator--top {
	top: -3px;

	svg {
		.bg--bottom {
			fill: transparent;
		}

		.bg--top {
			fill: $dark-blue;
		}
	}
}

.header__slider {


	.seperator--bottom {
		bottom: 8px;
	}
}

.seperator--bottom {
	bottom: -10px;
	padding: 0 !important;

	svg {
		.bg--bottom {
			fill: $dark-blue;
		}

		.bg--top {
			fill: transparent;
		}
	}
}


@include breakpoint(large) {
	.header__slider {
		.seperator--bottom {
			bottom: -10px;
		}
	}
}
