@mixin fussballer-badge($width) {
	width: #{$width}px;
	height: #{$width}px;
}

.fussballer__img {
	position: relative;
	margin-bottom: -100px;
	max-width: 400px;
	@include margin-auto;
}

.fussballer__badge {
	position: absolute;
	bottom: 125px;
	right: 0px;
	@include fussballer-badge(150);
}

@media screen and (min-width: 640px) and (max-width: 710px) {
	.fussballer__img {
		bottom: -75px;
	}
}


@include breakpoint(large) {
	.fussballer__badge {
		@include fussballer-badge(200);
	}

	.fussballer__img {
		max-width: 500px;
	}

	.fussballer {
		.y-center--medium {
			margin-top: $gap*2;
		}
	}
}
