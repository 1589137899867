.slider__item {
	position: relative;
}

.slider__content {
	position: absolute;
	width: 100%;
	top: 25%;
	font-size: 14px;

	h2{
		font-size: 2em;
		line-height: 1.2;
		text-shadow: 1px 1px 30px transparentize($white, 0.5);
		white-space: nowrap;
		span {
			display: block;
			&:last-child {
				margin-left: 1.5em;
			}
		}
	}

	.badge--wechseln {
		width: 60%;
		float: right;
	}
}

@include breakpoint($xsmall) {
	.slider__content {
		font-size: 18px;
	}
}

@include breakpoint(medium) {
	.slider__content {
		font-size: 22px;

		.badge--wechseln {
			float: none;
			margin: 0 auto;
		}
	}
}

@include breakpoint(large) {
	.slider {
		height: 100%;
	}

	.slider__wrapper {
		height: 100%;
	}

	.slider__item {
		height: 100%;

	}

	.slider__item {
		height: 100%;
	}

	.slider__content {
		font-size: 32px;

		.badges {
			position: relative;
			.badge {
				position: absolute;

				img {
					width: 100%;
				}
			}

			.badge--wechseln {
				z-index: 150;
				right: 20%;
			}

			.badge--oeko {
				z-index: 125;
				width: 25%;
				right: 5px;
				top: 200px;
			}
		}
	}

	.slider__image {
		height: 100%;
		.background-image {
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center top;
		}
	}
}

@include breakpoint(xlarge) {
	.slider__content {
		font-size: 36px;
	}
}


