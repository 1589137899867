$button-large: $gap*3;

.button {
	font-weight: 700;

	&.large, {
		padding: $gap/1.5 $button-large;
		width: 100%;
	}
}

.button-group {
	.button {
		&.large {
			font-size: 1.25rem;

			//margin-left: $gap/2;
			//margin-right: $gap/2;
		}
	}
}

.button--space {
	margin-top: $gap;
}

@include breakpoint(medium) {
	.button {
		&.large {
			width: initial;
		}
	}

	.button-center--medium {
		text-align: center;
	}
}

.button--purple {
	position: relative;
	overflow: hidden;
	//border-radius: $border-radius;

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: block;
		z-index: 0;
		border-radius: $border-radius;
		transition: all 300ms ease;
	}

	span {
		position: relative;
		z-index: 1;
	}

	&::before {
		@include gradient(to right, $dark-purple, $light-purple);
	}

	&::after {
		background-color: $dark-purple;
		opacity: 0;
	}

	@include hov-foc {
		&::before {
			opacity: 0;
		}

		&::after {
			opacity: 1;
		}
	}
}
