$table-color: $dark-gray;

table {
	color: $table-color;
	margin-bottom: $gap;

	thead {
		//color: $white;
		border: $table-border;
		background-color: $blue-02;

		th {
			text-align: center;
		}
	}

	tbody {
		tr {
			border: $table-border;
		}

		td {
			text-align: center;
			border: $table-border;
		}

		tr, td {
			&:first-child {
				font-weight: bold;
				color: $blue-02;
			}
		}

	}


}

.table-scroll {
	table {
		width: 100%;
	}
}

@include breakpoint(medium) {
	.table-scroll {
		overflow-x: initial;
	}
}
