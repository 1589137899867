.checklist {
	list-style: none;
	text-align: left;

	li {
		@include icon-check;
		&::before {
			margin-left: -21px;
			padding-right: 5px;
			font-size: 0.85rem;
			padding-bottom: 2px;
			margin-top: -4px;
			@include icon;
		}

		&.empty {
			&::before {
				display: none;
			}
		}
	}
}
