@include hamburger--squeeze();

.header__top {
	position: relative;
}

.hamburger {
	margin-top: 4px;
	position: absolute;
	right: $gap/2;
	z-index: 250;
	top: 50%;
	transform: translateY(-50%);
	padding-right: 0;
	&:hover {
		opacity: 1;
	}
}
.hamburger-inner {
	&,
	&::after,
	&::before {
		background-color: $white;
	}
}

@include breakpoint(large) {
	.home {
		&body, html {
			height: 100%;
		}

		header {
			height: 100%;
			width: 100%;
		}
	}
}

@include breakpoint(medium) {
	.logo {
		float: right;
		img {
			width: 150px;
		}
	}
}

.header__top {
	.blue {
		margin-top: $gap/1.5;
		margin-bottom: $gap/3;
	}
}

@include breakpoint(medium down) {
	.header__top {
		h1 {
			font-size: rem-calc(26);
		}
	}
}
