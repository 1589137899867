.accordion-item {
	.accordion-title {
		border-radius: $border-radius !important;
		border-bottom: 1px solid $white;
		font-size: 1rem;
		font-weight: 600;
		transition: all 300ms ease;
	}

	&.is-active {
		.accordion-title {
			border-bottom: none !important;
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
			background-color: $blue-02;
		}
	}
	margin-bottom: $gap/2;
}

.accordion-content {
	border-top: none;
	border-bottom-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
	border-bottom: 1px solid $white;
}
