.tabs-panel {
	padding: 0;

	.callout {
		margin: 10px 0px;

	}
}

.tabs-content {
	margin-top: -2px;
	padding: $gap/2;
	padding-top: $gap;
	margin-bottom: $gap;
	border-bottom-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
}

.tabs {
	border: none;
}

.tabs {
	&.expanded {
		display: table;
		table-layout: fixed;
		width: 100%;

		.tabs-title {
			float: none;
			display: table-cell;
			width: 100%;
		}
	}

	.tabs-title {
		&:last-child {
			a {
				border-left: none;
				border-top-right-radius: $border-radius;
			}
		}

		&:first-child {
			a {
				border-top-left-radius: $border-radius;
			}
		}

		a {
			text-align: center;
			font-weight: bold;
			font-size: 1.25rem;
			transition: all 300ms ease;
			border: 1px solid white;
			@include hov-foc {
				color: $white;
			}
		}

		&.is-active {
			a {
				cursor: default;
				border-bottom: 0;
				background-color: $blue-02;

			}

			.button--purple {
				&::before,
				&::after {
					opacity: 0;
				}
			}
		}
	}
}
