sub {
	font-weight: 400;
	font-size: 0.65em;
}

a[href$=".pdf"] {
	@include icon-file-pdf-o;
	&::before {
		padding-right: 5px;

		padding-bottom: 2px;
		margin-top: -2px;
		@include icon;
	}

	&:not(.normal) {
		font-size: $lead-font-size;

		&::before {
			font-size: 1.25rem;
		}
	}
}

.badges {
	a[href$=".pdf"] {
		&::before {
			display: none;
		}
	}
}

.has-tip {
	.icon {
		margin-bottom: 2px;
	}
}

img {
	margin-bottom: $paragraph-margin-bottom;
}

.thumbnail {
	img {
		margin-bottom: 0;
	}
}

address {
	font-style: normal;
}

a {
	font-weight: bold;
}


@include breakpoint(medium) {
	.y-center--medium {
		@include y-center;
	}
}

@include breakpoint(large) {
	.lead--large {
		font-size: $lead-font-size;
	}
}

