form {
	textarea {
		min-height: 100px;
	}
}

.form-content {
	background-color: darken($primary-color, 5%);
	padding: $gap;
	border-radius: $global-radius;
	position: relative;
}

.form-disabled {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: darken($primary-color, 5%);
	opacity: 0.5;
	top: 0;
	left: 0;
	z-index: 99;
}

.is-invalid-input:not(:focus) {
	background-color: $white;
}

.is-invalid-input {
	margin-bottom: 7.5px;
}


@include breakpoint(large) {
	form {
		textarea {
			min-height: 150px;
		}
	}
}
