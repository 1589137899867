$figure-max: 125px;

.media-object {
	.thumbnail {
		max-width: $figure-max;
		margin-bottom: 10px;
	}

	span {
		font-size: 0.85em;
		line-height: 1;
	}

	figure {
		margin: 0;
		margin-bottom: $gap/1.5;

		figcaption {
			line-height: 1;
			font-size: 0.85em;
			max-width: $figure-max;
		}
	}
}
