$badge-shadow: 10px 10px 10px transparentize($black, 0.2);

.badge {
	border-radius: 50%;
	box-shadow: $badge-shadow;

	img {
		margin-bottom: 0;
	}
}

.badge--content {
	max-width: 200px;
	@include margin-auto;
}

@include breakpoint(medium) {
	.badge--content {
		max-width: 250px;
	}
}
