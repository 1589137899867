.footer-nav {
	.menu {
		display: table;
		margin: 0 auto;
	}
}

footer {
	p {
		font-size: 0.8rem;
	}
}
