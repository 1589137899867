@font-face {
    font-family: "icon";
    src: url('../fonts/iconfont/icon.eot');
    src: url('../fonts/iconfont/icon.eot?#iefix') format('eot'),
    url('../fonts/iconfont/icon.woff') format('woff'),
    url('../fonts/iconfont/icon.ttf') format('truetype'),
    url('../fonts/iconfont/icon.svg#icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon {
    font-family:"icon";
    display:inline-block;
    vertical-align:middle;
    line-height:1;
    font-weight:normal;
    font-style:normal;
    speak:none;
    text-decoration:inherit;
    text-transform:none;
    text-rendering:optimizeLegibility;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}

@mixin icon() {
        font-family:"icon";
        display:inline-block;
        vertical-align:middle;
        line-height:1;
        font-weight:normal;
        font-style:normal;
        speak:none;
        text-decoration:inherit;
        text-transform:none;
        text-rendering:optimizeLegibility;
        -webkit-font-smoothing:antialiased;
        -moz-osx-font-smoothing:grayscale;
}

.icon--lg {
                      font-size: 1.3333333333333333em;
                      line-height: 0.75em;
                      vertical-align: -15%;
                  }

.icon--2x { font-size: 2em; }
.icon--3x { font-size: 3em; }
.icon--4x { font-size: 4em; }
.icon--5x { font-size: 5em; }
.icon--fw {
                      width: 1.2857142857142858em;
                      text-align: center;
                  }

@mixin icon-check() {&:before { content: "\EA01" }}
@mixin icon-angle-left() {&:before { content: "\EA02" }}
@mixin icon-angle-right() {&:before { content: "\EA03" }}
@mixin icon-file-pdf-o() {&:before { content: "\EA04" }}
@mixin icon-question-circle() {&:before { content: "\EA05" }}


@mixin icon-check--after() {&:after { content: "\EA01" }}
@mixin icon-angle-left--after() {&:after { content: "\EA02" }}
@mixin icon-angle-right--after() {&:after { content: "\EA03" }}
@mixin icon-file-pdf-o--after() {&:after { content: "\EA04" }}
@mixin icon-question-circle--after() {&:after { content: "\EA05" }}


.icon-check { @include icon-check(); }
.icon-angle-left { @include icon-angle-left(); }
.icon-angle-right { @include icon-angle-right(); }
.icon-file-pdf-o { @include icon-file-pdf-o(); }
.icon-question-circle { @include icon-question-circle(); }


.icon-check--after { @include icon-check--after(); }
.icon-angle-left--after { @include icon-angle-left--after(); }
.icon-angle-right--after { @include icon-angle-right--after(); }
.icon-file-pdf-o--after { @include icon-file-pdf-o--after(); }
.icon-question-circle--after { @include icon-question-circle--after(); }
