@mixin gradient($deg, $from, $to) {
	background: $from !important;
	background: linear-gradient($deg, $from, $to) !important;
}

@mixin hov-foc() {
	&:hover,
	&:focus {
		@content;
	}
}

@mixin margin-auto {
	margin-left: auto;
	margin-right: auto;
}
