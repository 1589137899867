#map {
	height: 400px;
	width: 100%;
	position: relative;

	.gm-style-iw {
		text-align: center;

		h3 {
			font-size: 1.25em;
		}

		h3, address, a {
			color: $primary-color;
		}

		address {
			margin-bottom: 5px;
		}
	}
}

.map {
	padding: $gap 0;

	.seperator--top {
		top: 25px;
	}

	.seperator--bottom {
		bottom: 20px;
	}
}


@include breakpoint(medium) {
	#map {
		height: 500px;
	}
}

@include breakpoint(large) {
	#map {
		height: 750px;
	}

	.map {
		.seperator--top {
			top: 20px;
		}

		.seperator--bottom {
			bottom: 20px;
		}
	}

}
